@import "common/css/variables";

#demo-route {
  background-color: $midnight;

  .container {
    padding-top: 24px;
  }

  .video-trouble {
    color: white;
    padding: 10px;
    font-size: 21px;
  }
}
