@import "common/css/variables";

#footer {
  background-color: $gray-light;
  display: flex;
  justify-content: center;
  min-height: 160px;
  padding: 16px;
  width: 100%;

  img {
    display: block;
    height: 24px;
    margin-bottom: 8px;
    width: auto;
  }

  .copyright {
    color: $gray-ccc;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .links {
    display: flex;

    a {
      color: $gray-ccc;
      font-size: 13px;
      font-weight: 400;
      margin-right: 24px;
      text-decoration: none;

      &:hover {
        color: $gray-dark;
        text-decoration: underline;
      }
    }
  }

  .container {
    align-items: center;
    display: flex;
    max-width: 1080px;
    position: relative;
    width: 100%;
  }
}

.sm,
.xs {
  #footer {
    padding: 80px 16px 192px;

    img {
      display: inline-block;
    }

    .container {
      justify-content: center;

      > div {
        text-align: center;

        .links {
          flex-direction: column;

          a {
            margin: 0 0 12px;
          }
        }
      }
    }
  }
}
