@import "common/css/variables";

#delete-account-route {
  .route-page-header {
    min-height: 100px;
    padding: 16px 48px;

    > .container {
      margin: 24px 0;
    }
  }

  > .container {
    padding: 48px;

    h3 {
      margin-bottom: 24px;
    }

    ol {
      list-style-type: decimal;
      padding: 0 24px;
      word-break: break-word;

      li {
        margin-bottom: 24px;
      }
    }
  }
}
