@import "common/css/variables";

#meetings-route {
  .support-quick-starts {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: -24px 0 80px;
    padding: 0 16px;

    .container {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      > div {
        align-items: center;
        background: $white;
        border-radius: 8px;
        box-shadow: 0px 4px 4px rgba($black, 0.25);
        display: flex;
        flex-direction: column;
        flex: 1;
        font-size: 14px;
        line-height: 17px;
        min-height: 100px;
        padding: 16px 16px 24px;

        &:not(:last-child) {
          margin-right: 16px;
        }

        .support-quick-start--logo {
          margin-bottom: 8px;

          img {
            height: 64px;
            width: auto;
          }
        }

        .support-quick-start--text {
          font-weight: 300;
          text-align: center;
          margin-bottom: 16px;
          flex: 1;
        }

        .support-quick-start--cta {
          font-weight: 600;
          color: $midnight;
          text-decoration: none;
          text-align: center;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .support-faqs {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 16px;

    .container {
      > h2 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 60px;
      }

      .faqs {
        .faq-section {
          margin-bottom: 60px;

          .faq-title {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 24px;
          }

          .faq-img {
            text-align: center;

            &:not(:last-child) {
              margin-bottom: 16px;
            }

            img {
              width: auto;
              height: auto;
              max-width: 100%;
            }
          }

          &:last-child {
            margin-bottom: 152px;
          }

          ul {
            padding-left: 20px;
            li {
              list-style: disc;
            }
          }
        }
      }
    }
  }

  .support-email {
    display: flex;
    width: 100%;
    justify-content: center;
    background: linear-gradient(90deg, $orange 12.52%, $yellow 72.11%);
    height: 330px;
    padding: 0 16px;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $white;
      text-shadow: 1px 1px 2px rgba($black, 0.1);

      .headline {
        margin-bottom: 18px;
      }

      .support-email--body {
        margin-bottom: 32px;
      }
    }
  }

  .sm &,
  .xs &,
  ._initial & {
    .support-email {
      text-align: center;
    }
  }

  .sm & {
    .support-quick-starts {
      margin-bottom: 48px;

      .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > div {
          flex: 0 calc(50% - 8px);
          margin-bottom: 16px;

          &:not(:last-child) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .xs &,
  ._initial & {
    .support-quick-starts {
      margin-bottom: 48px;

      .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > div {
          flex: 0 100%;
          margin-bottom: 16px;

          &:not(:last-child) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
