@import "common/css/variables";

#header {
  background-color: $midnight;
  display: flex;
  height: $header-height;
  justify-content: center;
  padding: 16px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .header-nav {
      display: flex;

      .header-nav--toggle {
        align-items: center;
        cursor: pointer;
        display: flex;
        margin-right: 20px;

        img {
          display: block;
          width: 24px;
        }
      }

      .engage-logo {
        height: 32px;
        margin-right: 16px;
        width: auto;
        display: block;
      }
    }

    .cta {
      align-items: center;
      display: flex;

      > a {
        color: $white;
        font-size: 14px;
        font-weight: 500;
        margin-right: 32px;
        text-align: center;
        text-decoration: none;
      }

      > .btn {
        margin-right: 24px;
      }
    }
  }
}
