@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../fonts/Inter-Thin.woff2?v=3.18) format("woff2"),
    url(../fonts/Inter-Thin.woff?v=3.18) format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../fonts/Inter-ExtraLight.woff2?v=3.18) format("woff2"),
    url(../fonts/Inter-ExtraLight.woff?v=3.18) format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/Inter-Light.woff2?v=3.18) format("woff2"),
    url(../fonts/Inter-Light.woff?v=3.18) format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/Inter-Regular.woff2?v=3.18) format("woff2"),
    url(../fonts/Inter-Regular.woff?v=3.18) format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/Inter-Medium.woff2?v=3.18) format("woff2"),
    url(../fonts/Inter-Medium.woff?v=3.18) format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/Inter-SemiBold.woff2?v=3.18) format("woff2"),
    url(../fonts/Inter-SemiBold.woff?v=3.18) format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/Inter-Bold.woff2?v=3.18) format("woff2"),
    url(../fonts/Inter-Bold.woff?v=3.18) format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../fonts/Inter-ExtraBold.woff2?v=3.18) format("woff2"),
    url(../fonts/Inter-ExtraBold.woff?v=3.18) format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/Inter-Black.woff2?v=3.18) format("woff2"),
    url(../fonts/Inter-Black.woff?v=3.18) format("woff");
}
