@import "common/css/variables";

.faq {
  padding: 24px 32px;
  border: 1px solid $gray-e7;
  user-select: none;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 4px;

  &.closed {
    .faq-answer {
      display: none;
    }
  }

  &.open {
    .faq-question {
      margin-bottom: 16px;

      img {
        transform: rotate(0deg);
      }
    }
  }

  .faq-question {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    padding-right: 70px;
    position: relative;

    img {
      transform: rotate(180deg);
      position: absolute;
      right: -4px;
      top: -5px;
    }
  }

  .faq-answer {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    padding-right: 70px;

    p {
      strong {
        font-weight: 700;
      }

      em {
        font-style: oblique;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}
