.route-page-header {
  background-color: $midnight;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 16px;
  min-height: 290px;

  .container {
    margin: 70px 0;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .title-description {
      max-width: 540px;
    }
  }
}
