@import "common/css/variables";

#language-selector {
  font-size: 14px;
  font-weight: 500;
  position: relative;

  &:not(:only-child) {
    margin-right: 24px;
  }

  #language-selector--selected {
    align-items: center;
    color: $white;
    cursor: pointer;
    display: flex;

    img {
      margin-right: 6px;
    }
  }

  #language-selector--dropdown {
    background-color: $white;
    border-radius: 4px;
    box-shadow: 1px 1px 8px rgba($black, 0.1);
    right: -4px;
    position: absolute;
    max-height: 300px;
    overflow: auto;
    top: calc(100% + 12px);

    > .language-selector--dropdown-item {
      cursor: pointer;
      padding: 8px 12px;
      white-space: nowrap;
      text-align: center;

      &:hover {
        background-color: rgba($skyBlue, 0.2);
      }

      &.selected {
        background-color: $skyBlue;
        color: $white;
      }
    }
  }
}
