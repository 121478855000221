@import "common/css/variables";

#home-route {
  .content-body {
    padding: 16px;

    > div {
      display: flex;
      align-items: center;
      padding: 0 16px;

      &:not(:first-child) {
        margin-top: -80px;
      }

      > div {
        z-index: 1;
        width: 40%;
      }

      > img {
        width: 60%;

        &:first-child {
          margin-left: -70px;
        }

        &:last-child {
          margin-right: -100px;
        }
      }

      .headline {
        margin-bottom: 24px;
      }

      .content-body--text {
        line-height: 32px;
      }
    }
  }

  .hero,
  .get-started,
  .how-it-works {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 16px;

    .container {
      position: relative;
      max-width: 1080px;
      width: 100%;
      align-items: center;
      display: flex;
    }
  }

  .hero {
    background-color: $midnight;
    color: $white;
    flex: 1 1 637px;
    overflow-x: hidden;
    overflow-y: hidden;

    .container {
      position: relative;

      .hero-body {
        flex: 0 1 58%;
        max-width: 640px;
        z-index: 100;

        .headline {
          margin-bottom: 32px;

          i {
            color: $orange;
            font-style: normal;
          }
        }

        .bullets {
          .bullet {
            align-items: center;
            display: flex;
            font-weight: 400;
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 32px;
            }

            .bullet-text {
              line-height: 22px;

              strong {
                font-weight: 700;
              }
            }

            .bullet-icon {
              align-items: center;
              border-radius: 18px;
              display: flex;
              flex-shrink: 0;
              height: 36px;
              justify-content: center;
              margin-right: 16px;
              width: 36px;
            }

            &.bullet1 {
              .bullet-icon {
                background-color: $teal;
              }
            }
            &.bullet2 {
              .bullet-icon {
                background-color: $skyBlue;
              }
            }
            &.bullet3 {
              .bullet-icon {
                background-color: $orange;
              }
            }
          }
        }

        .hero-actions {
          display: flex;
          flex-wrap: wrap;

          .btn {
            margin-bottom: 16px;

            &:first-child {
              margin-right: 24px;
            }
          }
        }

        &.with-placeholder {
          flex: 0 1 50%;
        }
      }
    }

    .hero-video {
      width: 673.16px;
      position: absolute;
      right: -180px;

      video {
        height: auto;
      }
    }

    .hero-video--placeholder {
      height: 580px;
      position: absolute;
      right: -60px;

      img {
        height: 100%;
      }
    }
  }

  .get-started {
    background: linear-gradient(90deg, $orange 12.52%, $yellow 72.11%);
    color: $white;
    flex: 1 1 422px;
    position: relative;

    .container {
      > div {
        width: 40%;

        .headline {
          margin-bottom: 24px;
          text-shadow: 1px 1px 2px rgba($black, 0.1);
        }

        .get-started--body {
          line-height: 22px;
          margin-bottom: 24px;
          text-shadow: 1px 1px 2px rgba($black, 0.1);

          strong {
            font-weight: 600;
          }
        }

        .get-started--actions {
          display: flex;

          > button {
            margin-right: 24px;
          }

          > div {
            white-space: nowrap;

            a:not(:last-child) {
              margin-right: 24px;
            }
          }
        }
      }
    }

    .get-started--laptop {
      bottom: -16px;
      position: absolute;
      right: -233px;
      width: 828px;
    }
  }

  .how-it-works {
    background-color: $gray-med;
    padding: 65px 16px 278px;

    .container {
      flex-direction: column;

      .how-it-works--body {
        line-height: 32px;
        margin: 36px 0;
        max-width: 560px;
        text-align: center;
      }

      .my-engage-video-wrapper,
      iframe {
        height: auto;
        max-width: 848px;
        width: 100%;
        aspect-ratio: 16 / 9;
      }
    }
  }

  .md & {
    .get-started {
      flex: 1 1 auto;
      padding: 60px 16px 0;

      .container {
        display: block;

        > div {
          width: 100%;
          margin-bottom: 40px;

          .get-started--body {
            margin-bottom: 32px;
          }
        }

        .get-started--laptop {
          bottom: -3px;
          position: relative;
          right: auto;
          width: 100%;
        }
      }
    }
  }

  ._initial &,
  .sm &,
  .xs & {
    .content-body {
      padding: 45px 16px 16px;

      > div {
        flex-direction: column;
        padding: 0;

        &:not(:first-child) {
          margin-top: 0;
        }

        > div {
          order: 1;
          width: 100%;
        }

        img {
          order: 2;
          margin-left: 0;
          margin-right: 0;
          width: 150%;
        }
      }
    }

    .hero {
      flex: 1 1 auto;
      padding: 54px 16px 16px;

      .container {
        flex-direction: column;

        .hero-body {
          max-width: 640px;
          z-index: 100;

          .headline {
            font-size: 24px;
            line-height: 29px;
          }
        }

        .hero-actions {
          align-items: center;

          .btn:first-child {
            margin-right: 16px;
          }
        }

        .hero-video {
          display: flex;
          height: auto;
          justify-content: center;
          margin-top: -20px;
          position: relative;
          right: auto;
          width: 100%;

          video {
            height: auto;
          }
        }

        .hero-video--placeholder {
          position: relative;
          right: auto;
          width: 100%;
          height: auto;

          img {
            height: auto;
            width: 100%;
          }
        }
      }
    }

    .how-it-works {
      padding: 60px 16px;
    }

    .get-started {
      flex: 1 1 auto;
      padding: 60px 16px 0;

      .container {
        display: block;

        > div {
          width: 100%;
          margin-bottom: 80px;

          .headline {
            text-align: center;
          }

          .get-started--body {
            margin-bottom: 32px;
          }

          .get-started--actions {
            flex-direction: column;
            align-items: center;

            .btn {
              margin-right: 0;
              margin-bottom: 32px;
            }

            div {
              a:first-child {
                margin-left: 0;
              }
            }
          }
        }

        .get-started--laptop {
          bottom: -3px;
          position: relative;
          right: auto;
          width: 100%;
        }
      }
    }
  }

  ._initial &,
  .xs & {
    .hero .container {
      .hero-actions {
        flex-direction: column;

        .btn:first-child {
          margin: 0 0 16px;
        }
      }

      .hero-video {
        margin-top: -40px;
      }
    }
  }
}
