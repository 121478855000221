body {
  font-family: "Inter", sans-serif;
  font-size: $font-size-base;
  font-weight: 400;
  color: $default-text-color;
}

p {
  margin-bottom: 12px;

  a {
    color: $orange;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.headline {
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
}

h1.title {
  color: $white;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 24px;
}

p.title-description {
  color: $white;
  font-size: 14px;
  line-height: 17px;
  font-weight: 300;
  margin-bottom: 0;
}
