@import "common/css/variables";

#root {
  overflow: hidden;
  min-height: 100%;
  position: relative;
}

#myveeva-marketing {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  #not-found {
    padding: 24px;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: 800;
    color: $gray-ccc;
  }

  .content {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    margin-top: $header-height;
    overflow: hidden;
    flex: 1;
  }

  .container {
    position: relative;
    max-width: 1080px;
    width: 100%;
  }

  a.btn {
    text-decoration: none;
  }

  .btn {
    align-items: center;
    background-color: $white;
    border: none;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    line-height: 1;
    box-shadow: 0px 2px 2px rgba($black, 0.25);
    color: $default-text-color;

    &:hover {
      background-color: $gray-e7;
    }

    &-clear {
      background-color: transparent;
      padding: 0;
      border: none;
    }

    &.btn-sm {
      font-size: 14px;
      font-weight: 500;
      border-radius: 16px;
      height: 32px;
      padding: 6px 24px;
    }

    &.btn-md {
      font-size: 16px;
      font-weight: 600;
      border-radius: 18px;
      height: 36px;
      padding: 8px 24px;
    }

    &.btn-lg {
      font-size: 18px;
      font-weight: 500;
      border-radius: 22px;
      height: 44px;
      padding: 12px 32px;
    }

    &.btn-blue {
      background-color: $skyBlue;
      color: $white;

      &:hover {
        background-color: $twilightBlue;
      }
    }

    &.btn-orange {
      background-color: $orange;
      color: $white;

      &:hover {
        background-color: $burntOrange;
      }
    }

    &.btn-link {
      height: 32px;
      padding: 6px;
      border-radius: 4px;
      text-decoration: underline !important;
      background-color: transparent;
      box-shadow: none;

      &:hover {
        text-decoration: none !important;
        background-color: rgba($white, 0.1);
      }
    }
  }
}
