@import "common/css/variables";

#lsuFaq-route {
  .lsuFaq-header {
    background-color: $midnight;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 16px;
    min-height: 290px;

    .container {
      margin: 70px 0;
      color: $white;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title-description {
        max-width: 540px;
      }
    }
  }

  .lsuFaq-apps {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: -24px 0 80px;
    padding: 0 16px;

    .container {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      > div {
        align-items: center;
        background: $white;
        border-radius: 8px;
        box-shadow: 0px 4px 4px rgba($black, 0.25);
        display: flex;
        flex-direction: column;
        flex: 1;
        font-size: 14px;
        line-height: 17px;
        min-height: 100px;
        padding: 32px 16px 60px;

        &:not(:last-child) {
          margin-right: 16px;
        }

        .lsuFaq-app--logos {
          display: flex;
          flex-direction: column;
          align-items: center;

          .lsuFaq-app--icon {
            width: 60px;
            height: 60px;
            margin-bottom: 24px;
          }

          .lsuFaq-app--logo {
            height: 40px;
            width: auto;
            margin-bottom: 24px;
          }
        }

        .lsuFaq-app--text {
          font-weight: 400;
          text-align: center;
          margin-bottom: 48px;
          max-width: 66%;
          flex: 1;
        }

        .lsuFaq-app--cta {
          a:first-child {
            margin-right: 24px;
          }
        }
      }
    }
  }

  .lsuFaq-faqs {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 16px;

    .container {
      > h2 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 60px;
      }
    }
  }

  .sm &,
  .xs &,
  ._initial & {
    .lsuFaq-apps {
      margin-bottom: 48px;

      .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > div {
          flex: 0 100%;
          padding: 32px 16px;
          margin-bottom: 16px;

          &:not(:last-child) {
            margin-right: 0;
          }

          .lsuFaq-app--text {
            margin-bottom: 32px;
          }

          .lsuFaq-app--cta {
            a:first-child {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}
