$midnight: rgb(29, 47, 85);
$gray-light: #fafafa;
$gray-med: #f8f8f8;
$gray-dark: #898a8d;
$gray-ccc: #ccc;
$gray-e7: #e7e7e7;
$white: #fff;
$black: #000;
$orange: #f7981d;
$burntOrange: #d97e08;
$teal: #00b6b9;
$skyBlue: #449fd7;
$twilightBlue: #1a76a3;
$yellow: #fdb913;

$font-size-base: 18px !default;
$default-text-color: rgba($black, 0.9);

$header-height: 64px;
