@import "common/css/variables";

#android-permissions-route {
  .route-page-header {
    min-height: 100px;
    padding: 16px 48px;

    > .container {
      margin: 24px 0;
    }
  }

  > .container {
    position: relative;
    padding: 48px 24px;
    display: flex;
    justify-content: center;

    video {
      width: 100%;
      max-width: 480px;
      min-width: 200px;
    }
  }
}
