@import "common/css/variables";

#side-nav {
  flex: 0 0 100%;
  background: $midnight;
  bottom: 0;
  height: calc(100vh - #{$header-height});
  position: fixed;
  left: 0;
  top: $header-height;
  transform: translateX(-100%);
  width: 100%;
  z-index: 200;
  transition: transform 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  padding: 16px;
  color: $white;
  display: flex;
  flex-direction: column;

  &.open {
    transform: translateX(0%);
  }

  a,
  button {
    background: none;
    border: none;
    text-decoration: none;
    color: $white;
    padding: 16px 0;
    text-align: left;
    opacity: 0.9;

    &:hover {
      opacity: 1;
    }
  }
}
